import React, { lazy, Suspense, useEffect } from "react";
import { PageNotFound } from "common/page-not-found";
import { Route, Routes } from "react-router-dom";
import APP_ROUTES from "routes";
import LazyLoader from "./components/common/LazyLoader";
import ProtectedRoute from "components/common/protected-route";
import OfficeNotFound from "components/visitors-management/OfficeNotFound";
const Login = lazy(() => import("./components/login/LoginPage"));
const LogoutPage = lazy(() => import("./components/login/LogoutPage"));
const Admin = lazy(() => import("./components/Admin/Admin"));
const InternalAdmin = lazy(() =>
  import("./components/admin-internal/InternalAdmin")
);
const DashBoard = lazy(() => import("./components/dashboard/DashBoard"));
const Visitor = lazy(() => import("./components/visitors-management/Visitor"));

function App() {
  return (
    <Suspense fallback={<LazyLoader />}>
      <Routes>
        <Route path="/login" element={<Login />} />
        <Route path="/visit/:office_location" exact element={<Visitor />} />
        <Route path="/office-not-found" exact element={<OfficeNotFound />} />
        <Route element={<ProtectedRoute />}>
          <Route path="/" element={<DashBoard />} />
          {APP_ROUTES.map(({ path, element: Element }, i) => (
            <Route exact key={i} path={path} element={<Element />} />
          ))}
          <Route exact path="/admin" element={<Admin />} />
          <Route exact path="/admin-internal" element={<InternalAdmin />} />
          <Route exact path="/logout" element={<LogoutPage />} />
        </Route>
        <Route path="*" element={<PageNotFound />} />
      </Routes>
    </Suspense>
  );
}

export default App;
