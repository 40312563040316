import { Box, Typography } from "@mui/material";
import React from "react";

const OfficeNotFound = () => {
  return (
    <Box
      sx={{
        width: "100%",
        height: "100%",
        display: "flex",
        justifyContent: "center",
        alignContent: "center",
        alignItems: "center",
      }}
    >
      <Typography>
        😟{" "}
        <b>
          Sorry, there was a problem loading the page. Please seek admin
          assistance.
        </b>
      </Typography>
    </Box>
  );
};
export default OfficeNotFound;
